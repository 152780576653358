import { createGlobalStyle } from 'styled-components';

const SlpGlobalStyles = createGlobalStyle`

/*------------------------------
    Gen. GENERAL 
------------------------------*/
	
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-text-size-adjust: none;
}

body {
    margin: 0;
    padding: 0;
    color: #00539f;
    background: #fff;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.02em;
}

img {
    border: 0;
    vertical-align: top;
}

h1 {
    margin: 0 0 35px 0;
    padding: 0;
    font-family: 'MrAlex', arial, sans-serif;
    font-weight: normal !important;
    color: #00539f;
    font-size: 34px;
    line-height: 36px;
}

h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-family: 'Figtree-Light', arial, sans-serif;
    font-weight: normal !important;
    color: #00539f;
    font-size: 46px;
    line-height: 80px;
    text-align: center;
}

h3 {
    margin: 0 0 5px 0;
    padding: 0;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    font-weight: normal !important;
    color: #00539f;
    font-size: 18px;
    line-height: 20px;
}

h4 {
    margin: 0 0 25px 0;
    padding: 0 60px;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    font-weight: normal !important;
    color: #00539f;
    font-size: 23px;
    line-height: 130%;
}

h5 {
    margin: 0 0 35px 0;
    padding: 0;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    font-weight: normal !important;
    color: #00539f;
    font-size: 34px;
    line-height: 38px;
    text-align: center;
}

h6 {
    margin: 0 0 10px 0;
    padding: 0;
    font-family: 'MrAlex', arial, sans-serif;
    font-weight: normal !important;
    color: #646464;
    font-size: 18px;
    line-height: 20px;
}

hr {
    height: 1px;
    border: none;
    background: grey;
    margin: 50px 0;
    clear: both;
}

p {
    margin: 0 0 20px 0;
    padding: 0;
}

p.note {
    font-size: 13px;
    color: #9a9a9a;
}

strong {
    font-family: 'MrAlex', arial, sans-serif;
}

a:hover,
a:active,
a:focus,
object {
    outline: none;
}

a {
    text-decoration: none;
    color: #00447c;
    webkit-transition: color .15s ease-out;
    -moz-transition: color .15s ease-out;
    -o-transition: color .15s ease-out;
    -ms-transition: color .15s ease-out;
    transition: color .15s ease-out;
    transition: .25s ease-out;
}

a:hover {
    text-decoration: none;
    color: #00539f;
}

form,
input,
textarea {
    margin: 0;
    padding: 0;
    outline: none;
    -webkit-border-radius: 0;
}

sup,
sub {
    font-size: 18px;
    line-height: 18px;
    vertical-align: top;
}

.clear {
    clear: both;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
/* MARKETO FORM */

.mktoForm label.mktoLabel {
  font-family: 'Figtree', arial, sans-serif !important;
  font-size: 13px !important;
  font-weight: bold !important;
  line-height: 1.2em !important;
  color: #666 !important;
}
.mktoForm label.mktoLabel a {
  color: #55b34c !important;
  text-decoration: none !important;
}

.mktoForm label.mktoLabel[for='ExpressConsent'],
.mktoForm label.mktoLabel[for='ExpressConsent'] span,
.mktoForm label.mktoLabel[for='ExpressConsent'] a {
  font-family: 'Figtree', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}
.mktoForm label.mktoLabel[for='ExpressConsent'] sup {
  font-family: 'Helvetica-Condensed', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}
.mktoForm label.mktoLabel[for='referralConsent'],
.mktoForm label.mktoLabel[for='referralConsent'] span,
.mktoForm label.mktoLabel[for='referralConsent'] a {
  font-family: 'Helvetica-Condensed', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}
.mktoForm label.mktoLabel[for='referralConsent'] sup {
  font-family: 'Helvetica-Condensed', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}
.mktoForm label.mktoLabel[for='contestTerms'],
.mktoForm label.mktoLabel[for='contestTerms'] span,
.mktoForm label.mktoLabel[for='contestTerms'] a {
  font-family: 'Helvetica-Condensed', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}
.mktoForm label.mktoLabel[for='contestTerms'] sup {
  font-family: 'Helvetica-Condensed', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
}

.mktoForm input[type='text'],
.mktoForm input[type='url'],
.mktoForm input[type='email'],
.mktoForm input[type='tel'],
.mktoForm input[type='number'],
.mktoForm input[type='date'],
.mktoForm select.mktoField,
.mktoForm textarea.mktoField {
  -webkit-appearance: none !important;
  background-color: #fff !important;
  line-height: 1.5em !important;
  color: #000 !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  padding: 0.2em 0.3em !important;
  box-shadow: none !important;
  min-height: 1.9em !important;
  padding: 10px !important;
  margin-top: 8px !important;
}
.mktoForm textarea.mktoField {
  height: 150px !important;
}
button.mktoButton {

position: relative !important;

  top: 0 !important;

  padding: 16px 25px 14px 25px !important;

  margin: 0 5px 5px 0 !important;

  font-family: 'Figtree-Bold', arial, sans-serif !important;

  font-size: 16px !important;

  line-height: 16px !important;

  text-transform: uppercase !important;

  text-align: left !important;

  color: #fff !important;

  background: #55b34c !important;

  background-color: rgb(85, 179, 76);

  border: none !important;

  border-radius: 100px !important;

  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);

  transition: all 0.25s ease-out;

webkit-transition: all 0.25s ease-out;

-moz-transition: all 0.25s ease-out;

-o-transition: all 0.25s ease-out;

-ms-transition: all 0.25s ease-out;

transition: all 0.25s ease-out;
}

button.mktoButton:hover {
color: #fff !important;
background: #55aa4c !important;
top: -5px;
color: #55aa4c;
border-color: #55aa4c;
box-shadow: 0 5px 0 0 #0e4c80;
}
.mktoButtonRow {
  margin-top: 20px !important;
}
.mktoButtonWrap {
  margin-left: 10px !important;
}

@media screen and (max-width: 600px) {
  .mktoForm {
    padding: 0 !important;
  }
  .mktoButtonWrap {
    margin-left: 0 !important;
  }

  .mktoForm textarea.mktoField {
    height: auto !important;
  }
}


`;

export default SlpGlobalStyles;
