import * as React from 'react';
import loadable from '@loadable/component';
import 'aos/dist/aos.css';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import { graphql } from 'gatsby';
import GlobalStyles from '../../styles/slpGlobalStyles';
import { Helmet } from 'react-helmet';
import AOS from 'aos';

const StandalonePageTemplate = ({ data, location }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  const sectionsNode = data?.allContentfulSparlings?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  )?.node;

  const sectionsArray = sectionsNode?.sections;
  return !loaded ? (
    <>
      <Helmet
        htmlAttributes={{
          lang: sectionsNode?.node_locale || 'en',
        }}
      >
        <title>
          {sectionsNode?.title
            ? sectionsNode?.title
            : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
        </title>
        {sectionsNode?.metadata && (
          <meta name="description" content={sectionsNode?.metadata}></meta>
        )}
        {data?.allContentfulSparlings?.edges[0]?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <GlobalStyles />

      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (
              section?.type &&
              sectionList.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
    </>
  ) : (
    <>
      <GlobalStyles />

      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
    </>
  );
};

export default StandalonePageTemplate;

export const pageQuery = graphql`
  query allStandalonePagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulImageContainer: Boolean! # $contentfulMultimediaText: Boolean! # $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulSparlings(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          hasIndex
          title
          node_locale
          sections {
            __typename
            ...ImageWithPath @include(if: $contentfulImageWithPath)
            ...ImageContainer @include(if: $contentfulImageContainer)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
          }
        }
      }
    }
  }
`;
